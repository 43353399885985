import styled from "styled-components";

export const NavButtonWrapperEl = styled.div`
	width: calc(100% - 30px);
	margin: 15px 0 15px 15px;
	padding-left: 15px;
	text-align: left;
	background: transparent;
	border-radius: 3px;
	transition: background 0.2s, -moz-transform ease-in-out 0.2s;

	&:hover {
		cursor: pointer;
		background: var(--bg-d2);
	}

	& > .icon {
		display: inline-block;
		vertical-align: middle;
		height: 20px;
		width: 20px;
		margin-right: 12px;
	}

	& > .icon.wiki {
		background: var(--icon-wiki-lg);
	}

	& > .icon.company {
		background: var(--icon-company-lg);
	}

	& > .icon.legacy {
		background: var(--icon-legacy-lg);
	}

	& > .icon.search {
		background: var(--icon-search2-lg);
	}

	& > .icon.activity {
		background: var(--icon-activity-lg);
	}

	& > .icon.team {
		background: var(--icon-team-lg);
	}

	& > .icon.dev {
		background: var(--icon-dev-lg);
	}

	& > p {
		display: inline-block;
		vertical-align: middle;
		color: var(--txt-lg);
		font-size: 13px;
	}

	&.selected {
		background: var(--bg-d2);
	}

	&.selected > .icon.company {
		background: var(--icon-company-l);
	}

	&.selected > .icon.legacy {
		background: var(--icon-legacy-l);
	}

	&.selected > .icon.activity {
		background: var(--icon-search2-lg);
	}

	&.selected > .icon.activity {
		background: var(--icon-activity-l);
	}

	&.selected > .icon.team {
		background: var(--icon-team-l);
	}

	&.selected > .icon.dev {
		background: var(--icon-dev-l);
	}

	&.selected p {
		color: white;
	}

	@media screen and (max-width: 1200px) {
		padding-left: 0;
		text-align: center;

		& > .icon {
			margin-top: 10px;
			margin-bottom: 10px;
			margin-right: 0;
		}

		& > p {
			display: none;
		}
	}

	@media screen and (max-width: 480px) {
		padding-left: 0;
		text-align: center;
		width: 25%;
		float: left;
		margin: 1em 0;

		& > .icon {
			margin-top: 10px;
			margin-bottom: 10px;
			margin-right: 0;
		}

		& > p {
			display: none;
		}
	}
`;
