// Resources
import React, { Component } from "react";
import linkifyHtml from "linkify-html";

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
import TopNavWrapper from "./TopNavWrapper";
import Select from "react-select";
import DatePicker from "react-date-picker";

// Styles
import { DashboardWrapperEl, GlobalStyle } from "./styles/DashboardWrapper";

class SearchWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			sort: "",
			showData: false,

			// Salesforce records and display
			salesforce_records: [],
			salesforce_records_displayed: [],

			// Datalists for Salesforce Data
			datalist_Industry: [],
			datalist_Sub_Industry__c: [],
			datalist_Type: [],
			datalist_Account_Grade__c: [],
			datalist_Headquarted_In__c: [],
			datalist_Network: [],
			datalist_Agency: [],

			modifiedStart: "",
			modifiedEnd: "",
			createdStart: "",
			createdEnd: "",

			// Datalists for CSD Data
			csdLanguageList: [
				"English",
				"Czech",
				"Danish",
				"Dutch",
				"Finnish",
				"French",
				"German",
				"Greek",
				"Hebrew",
				"Hungarian",
				"Indonesian",
				"Italian",
				"Japanese",
				"Korean",
				"Latvian",
				"Lithuanian",
				"Mandarin (Chinese)",
				"Norwegian",
				"Persian",
				"Polish",
				"Portuguese",
				"Romanian",
				"Russian",
				"Spanish",
				"Swedish",
				"Thai",
				"Turkish",
				"Ukrainian",
				"Vietnamese",
				"Welsh"
			],
			csdStrategyTypeList: [
				"Reiteration (No Incentive)",
				"Incentive",
				"Low Stock Prompt (No Incentive)",
				"Save Your Cart (Cart Rebuilder)",
				"In-Stock Alert (LC Only)",
				"Coupon Corrector",
				"Ad Extension Manager",
				"Coupon Analytics",
				"Recommend: Cross-Sell Complimentary Items",
				"Recommend: Cross-Sell Alternative Items",
				"Recommend: Downsell Recs",
				"Recommend: Upsell",
				"Recommend: Targeted Subtotal",
				"Recommend: Low Stock Alerts",
				"Recommend: Popular Picks",
				"Recommend: Bestsellers",
				"Recommend: Personalized/Targeted Recs",
				"Recommend: Client's choice",
				"Recommend: Discount Deals",
				"Recommend: Back in stock modal",
				"Recommend: Previously Viewed",
				"Recommend: Replenishment reminders",
				"Recommend: Bundling",
				"Recommend: Seasonal/Event-Based",
				"Recommend: Location-Based",
				"Recommend: Product Comparison",
				"Recommend: New Arrivals",
				"Recommend: Custom"
			],
			csdIncentiveTypeList: ["No Incentive", "Multi Use Coupon", "Single Use Coupon (Coupon List)"],
			csdDiscountDescriptionList: ["%", "$"],
			csdDesktopLaunchList: [
				"Abandonment",
				"Proactive (Modal)",
				"Proactive (In-Page)",
				"Abandonment & Proactive",
				"Test Abandonment vs Proactive",
				"User Action Based (On Add-To-Cart)",
				"Custom"
			],
			csdMobileLaunchList: [
				"Back Off Page",
				"Back Off Site",
				"Quick Scroll",
				"Proactive (Modal)",
				"Proactive (In-Page)",
				"User Action Based (On Add-To-Cart)",
				"Custom"
			],
			csdPagesList: [
				"Home",
				"Entrance",
				"Category",
				"Product",
				"Cart",
				"Checkout",
				"Register",
				"Login",
				"Landing",
				"Confirmation"
			],
			csdStagesList: [
				"Bounce - First page view",
				"Page View -  After a page is viewed",
				"Product View - After an item is viewed",
				"Pre-Cart - Before item is added to cart",
				"Active Cart - After item is added to cart",
				"Pre-Checkout - Before user reaches checkout page",
				"Active-Checkout - After user reaches checkout page",
				"Post-Purchase - After an item is bought",
				"Pre-Submit Only - Before email is submitted",
				"Post-Submit Only - After email is submitted",
				"Anonymous Visitor - Anonymous email capture",
				"Lifecycle - Specify point in lifecycle",
				"Coupon Corrector - After X failed coupon attempts"
			],
			csdVisitorsList: [
				"Not logged in",
				"Logged in",
				"New visitor: first session, cookie based",
				"Return visitor: sessions > 1, cookie based",
				"Return customer: sale seen, cookie based",
				"Data Feed: List/API provided by client"
			],
			csdGeoLocationsList: ["US", "UK"],

			// Filter values for the Salesforce Data form
			filterIndustry: [],
			filterSubIndustry: [],
			filterAccountStatus: [],
			filterGrade: [],
			filterHeadquartered: [],
			filterNetwork: [],
			filterAgency: [],

			// CSD Filters
			filter_solution: "",
			filter_language_operator: "contains",
			filter_language: "",
			filter_strategy_type_operator: "contains",
			filter_strategy_type: "",
			filter_incentive_type_operator: "contains",
			filter_incentive_type: "",
			filter_discount_description_operator: "contains",
			filter_discount_description: "",
			filter_desktop_launch_method_operator: "contains",
			filter_desktop_launch_method: "",
			filter_mobile_launch_method_operator: "contains",
			filter_mobile_launch_method: "",
			filter_pages_operator: "contains",
			filter_pages: "",
			filter_stages_operator: "contains",
			filter_stages: "",
			filter_visitors_operator: "contains",
			filter_visitors: "",
			filter_locations_operator: "contains",
			filter_locations: "",
			filter_contains_mockups: "",
			filter_contains_deliverables: "",
			filter_contains_testing_instructions: ""
		};
	}

	filterForms = (record) => {
		if (this.state.filter_solution && record.type !== this.state.filter_solution) {
			return false;
		}
		if (this.state.createdEnd && new Date(this.state.createdEnd) < new Date(record.createdAt)) {
			return false;
		}
		if (this.state.createdStart && new Date(this.state.createdStart) > new Date(record.createdAt)) {
			return false;
		}
		if (this.state.modifiedEnd && new Date(this.state.modifiedEnd) < new Date(record.updatedAt)) {
			return false;
		}
		if (this.state.modifiedStart && new Date(this.state.modifiedStart) > new Date(record.updatedAt)) {
			return false;
		}

		let fields = Object.keys(record.fields);
		for (var i = 0; i < fields.length; i++) {
			let field = fields[i];
			if (field === "mockups" && !record.fields[field] && this.state["filter_contains_" + field]) {
				return false;
			} else if (field === "deliverables" && !record.fields[field] && this.state["filter_contains_" + field]) {
				return false;
			} else if (
				field === "testing_instructions" &&
				!record.fields[field] &&
				this.state["filter_contains_" + field]
			) {
				return false;
			} else if (
				this.state["filter_" + field] &&
				this.state["filter_" + field + "_operator"] === "does_not_contain" &&
				(!record.fields[field] || record.fields[field].toLowerCase().indexOf(this.state["filter_" + field].toLowerCase()) !== -1)
			) {
				return false;
			} else if (
				this.state["filter_" + field] &&
				this.state["filter_" + field + "_operator"] === "equals" &&
				(!record.fields[field] || record.fields[field].toLowerCase() !== this.state["filter_" + field].toLowerCase())
			) {
				return false;
			} else if (
				this.state["filter_" + field] &&
				this.state["filter_" + field + "_operator"] === "not_equal" &&
				(!record.fields[field] || record.fields[field].toLowerCase() === this.state["filter_" + field].toLowerCase())
			) {
				return false;
			} else if (
				this.state["filter_" + field + "_operator"] === "contains" &&
				this.state["filter_" + field] &&
				(!record.fields[field] || record.fields[field].toLowerCase().indexOf(this.state["filter_" + field].toLowerCase()) === -1)
			) {
				return false;
			} else if (
				this.state["filter_" + field + "_operator"] === "word" &&
				this.state["filter_" + field] &&
				(!record.fields[field] || record.fields[field].toLowerCase().match(new RegExp("(^|[^\\w])" + this.state["filter_" + field].toLowerCase() + "($|[^\\w])")) === null)
			) {
				return false;
			}
		}

		return true;
	};

	filterAccounts = () => {
		const {
			filterIndustry,
			filterSubIndustry,
			filterAccountStatus,
			filterGrade,
			filterHeadquartered,
			filterNetwork,
			filterAgency,
			salesforce_records
		} = this.state;
		const filtered = salesforce_records.filter((record) => {
			let matches = true;
			if (filterIndustry.length > 0 && record.Industry && filterIndustry.map((a) => a.value).join("|").toLowerCase().indexOf(record.Industry.toLowerCase()) === -1) {
				matches = false;
			}
			if (
				filterSubIndustry.length > 0 &&
				(!record.Sub_Industry__c ||
					filterSubIndustry
						.map((a) => a.value)
						.join("|").toLowerCase()
						.match(record.Sub_Industry__c.toLowerCase().replace(/;/g, "|")) == null)
			) {
				matches = false;
			}
			if (filterAccountStatus.length > 0 && (!record.Type || filterAccountStatus.map((a) => a.value).join("|").toLowerCase().indexOf(record.Type.toLowerCase()) === -1)) {
				matches = false;
			}
			if (filterGrade.length > 0 && (!record.Account_Grade__c || filterGrade.map((a) => a.value).join("|").toLowerCase().indexOf(record.Account_Grade__c.toLowerCase()) === -1)) {
				matches = false;
			}
			if (
				filterHeadquartered.length > 0 &&
				(!record.Headquarted_In__c || filterHeadquartered.map((a) => a.value).join("|").toLowerCase().indexOf(record.Headquarted_In__c.toLowerCase()) === -1)
			) {
				matches = false;
			}
			if (
				filterNetwork.length > 0 &&
				(!record.Network__r || filterNetwork.map((a) => a.value).join("|").toLowerCase().indexOf(record.Network__r.Name.toLowerCase()) === -1)
			) {
				matches = false;
			}
			if (
				filterAgency.length > 0 &&
				(!record.Agency__r || filterAgency.map((a) => a.value).join("|").toLowerCase().indexOf(record.Agency__r.Name.toLowerCase()) === -1)
			) {
				matches = false;
			}
			return matches;
		});

		this.setState({ salesforce_records_displayed: filtered });
	};

	componentDidMount() {
		// Query live, paused, dead SF accounts.
		this.Auth.querySalesforce(
			"select Id, Account_ID__c, Name, Industry, Sub_Industry__c, Type, Account_Grade__c, Headquarted_In__c, Network__r.Name, Agency__r.Name from Account where Type = 'Live' OR Type = 'Paused' OR Type = 'Dead' limit 10000"
		)
			.then(async (res) => {
				const records = res.data.records;
				if (records && records.length > 0) {
					// Extract unique values from records for each field
					const uniqueValues = (key) => [
						...new Set(
							records.flatMap((record) =>
								record[key] ? record[key].split(";").map((val) => val.trim()) : []
							)
						)
					];
					const uniqueValuesName = (key, key2) => [
						...new Set(records.map((record) => record[key] && record[key][key2]).filter((val) => val))
					];
					const csd_results = await this.Auth.getCompaniesAll().then((res) => {
						return res.results;
					});
					let recordsNew = records
						.map((record) => {
							let searchTerm = record.name + "|" + record._id + "|" + record.Account_ID__c;
							record.csdCompany = csd_results.find((result) => result.search.match(searchTerm));
							return record;
						})
						.filter((record) => record.csdCompany);
					this.setState({
						salesforce_records: recordsNew,
						salesforce_records_displayed: recordsNew,
						datalist_Industry: uniqueValues("Industry").sort(),
						datalist_Sub_Industry__c: uniqueValues("Sub_Industry__c").sort(),
						datalist_Type: uniqueValues("Type").sort(),
						datalist_Account_Grade__c: uniqueValues("Account_Grade__c").sort(),
						datalist_Headquarted_In__c: uniqueValues("Headquarted_In__c").sort(),
						datalist_Network: uniqueValuesName("Network__r", "Name").sort(),
						datalist_Agency: uniqueValuesName("Agency__r", "Name").sort()
					});
				} else {
					this.setState({
						salesforce_records: records,
						salesforce_records_displayed: records,
						datalist_Industry: [],
						datalist_Sub_Industry__c: [],
						datalist_Type: [],
						datalist_Account_Grade__c: [],
						datalist_Headquarted_In__c: [],
						datalist_Network: [],
						datalist_Agency: []
					});
				}
			})
			.catch((err) => {
				console.log("[ componentDidMount ] POST error:", err);
			});
	}

	render() {
		return (
			<React.Fragment>
				<GlobalStyle />
				<DashboardWrapperEl>
					<div className={"header-wrapper company app"}>
						<TopNavWrapper page={this.props.page} user={this.props.user} />
					</div>
					<div className={"body-wrapper search-wrapper"}>
						<div className="search-header">
							<h1>CSD Search</h1>
						</div>
						<div className="card" style={{ width: "calc(50% - 2rem)", margin: "1rem" }}>
							<div className="card-header">
								<h2>Salesforce Data</h2>
							</div>
							<div className="card-body">
								<form className="form">
									<div className="input-group">
										<label className="form-label">Industry</label>
										<Select
											value={this.state.filterIndustry}
											options={this.state.datalist_Industry.map((data) => ({
												value: data,
												label: data
											}))}
											isSearchable={true}
											className="form-input"
											isMulti={true}
											onChange={(o) => {
												this.setState({ filterIndustry: o }, this.filterAccounts);
											}}
										/>
									</div>
									<div className="input-group">
										<label className="form-label">Sub-industry</label>
										<Select
											value={this.state.filterSubIndustry}
											options={this.state.datalist_Sub_Industry__c.map((data) => ({
												value: data,
												label: data
											}))}
											isSearchable={true}
											className="form-input"
											isMulti={true}
											onChange={(o) => {
												this.setState({ filterSubIndustry: o }, this.filterAccounts);
											}}
										/>
									</div>
									<div className="input-group">
										<label className="form-label">Account Status</label>
										<Select
											value={this.state.filterAccountStatus}
											options={this.state.datalist_Type.map((data) => ({
												value: data,
												label: data
											}))}
											isSearchable={true}
											className="form-input"
											isMulti={true}
											onChange={(o) => {
												this.setState({ filterAccountStatus: o }, this.filterAccounts);
											}}
										/>
									</div>
									<div className="input-group">
										<label className="form-label">Grade</label>
										<Select
											value={this.state.filterGrade}
											options={this.state.datalist_Account_Grade__c.map((grade) => ({
												value: grade,
												label: grade
											}))}
											isSearchable={true}
											className="form-input"
											isMulti={true}
											onChange={(o) => {
												this.setState({ filterGrade: o }, this.filterAccounts);
											}}
										/>
									</div>
									<div className="input-group">
										<label className="form-label">Headquartered in</label>
										<Select
											value={this.state.filterHeadquartered}
											options={this.state.datalist_Headquarted_In__c.map((data) => ({
												value: data,
												label: data
											}))}
											isSearchable={true}
											className="form-input"
											isMulti={true}
											onChange={(o) => {
												this.setState({ filterHeadquartered: o }, this.filterAccounts);
											}}
										/>
									</div>
									<div className="input-group">
										<label className="form-label">Network</label>
										<Select
											value={this.state.filterNetwork}
											options={this.state.datalist_Network.map((data) => ({
												value: data,
												label: data
											}))}
											isSearchable={true}
											className="form-input"
											isMulti={true}
											onChange={(o) => {
												this.setState({ filterNetwork: o }, this.filterAccounts);
											}}
										/>
									</div>
									<div className="input-group">
										<label className="form-label">Agency</label>
										<Select
											value={this.state.filterAgency}
											options={this.state.datalist_Agency.map((data) => ({
												value: data,
												label: data
											}))}
											isSearchable={true}
											className="form-input"
											isMulti={true}
											onChange={(o) => {
												this.setState({ filterAgency: o }, this.filterAccounts);
											}}
										/>
									</div>
								</form>
							</div>
						</div>
						<div className="card" style={{ width: "calc(50% - 2rem)", margin: "1rem" }}>
							<div className="card-header">
								<h2>CSD Data</h2>
							</div>
							<form className="form">
								<div className="input-group">
									<label className="form-label">Solution:</label>
									<select
										className="form-input"
										list="solutionList"
										value={this.state.filter_solution}
										onChange={(e) => {
											this.setState({ filter_solution: e.target.value }, this.filterAccounts);
										}}
									>
										<option value="">Any type</option>
										<option value="tt">TT</option>
										<option value="lc">LC</option>
										<option value="em">EM</option>
										<option value="ce">CE</option>
										<option value="nc">NC</option>
									</select>
									{/* <Select
										value={this.state.filter_solution}
										options={[
											{
												value: "tt",
												label: "TT"
											},
											{
												value: "lc",
												label: "LC"
											},
											{
												value: "em",
												label: "EM"
											},
											{
												value: "ce",
												label: "CE"
											},
											{
												value: "nc",
												label: "NC"
											}
										]}
										isSearchable={true}
										className="form-input"
										isMulti={true}
										onChange={(o) => {
											this.setState({ filter_solution: o }, this.filterAccounts);
										}}
									/> */}
								</div>
								<div className="input-group">
									<label className="form-label">Created after</label>
									<DatePicker
										locale="en-US"
										calendarIcon={null}
										onChange={(e) => {
											this.setState({ createdStart: e }, this.filterAccounts);
										}}
										value={this.state.createdStart ? new Date(this.state.createdStart) : null}
										className="form-input"
										x
									/>
								</div>
								<div className="input-group">
									<label className="form-label">Created before</label>
									<DatePicker
										locale="en-US"
										calendarIcon={null}
										onChange={(e) => {
											this.setState({ createdEnd: e }, this.filterAccounts);
										}}
										value={this.state.createdEnd ? new Date(this.state.createdEnd) : null}
										className="form-input"
									/>
								</div>
								<div className="input-group">
									<label className="form-label">Modified after</label>
									<DatePicker
										locale="en-US"
										calendarIcon={null}
										onChange={(e) => {
											this.setState({ modifiedStart: e }, this.filterAccounts);
										}}
										value={this.state.modifiedStart ? new Date(this.state.modifiedStart) : null}
										className="form-input"
									/>
								</div>
								<div className="input-group">
									<label className="form-label">Modified before</label>
									<DatePicker
										locale="en-US"
										calendarIcon={null}
										onChange={(e) => {
											this.setState({ modifiedEnd: e }, this.filterAccounts);
										}}
										value={this.state.modifiedEnd ? new Date(this.state.modifiedEnd) : null}
										className="form-input"
									/>
								</div>

								<div>
									<div className="input-group">
										<label className="form-label">Language</label>
										<div className="select-group">
											<select
												className="form-input"
												value={this.state.filter_language_operator}
												onChange={(e) =>
													this.setState({ filter_language_operator: e.target.value })
												}
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											value={this.state.filter_language}
											onChange={(e) => this.setState({ filter_language: e.target.value })}
											className="form-input"
											list="languageList"
											type="search"
										/>
										<datalist id="languageList">
											{this.state.csdLanguageList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>

									<div className="input-group">
										<label className="form-label">Strategy Type</label>
										<div className="select-group">
											<select
												className="form-input"
												value={this.state.filter_strategy_type_operator}
												onChange={(e) =>
													this.setState({ filter_strategy_type_operator: e.target.value })
												}
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											className="form-input"
											list="strategyTypeList"
											value={this.state.filter_strategy_type}
											onChange={(e) => this.setState({ filter_strategy_type: e.target.value })}
											type="search"
										/>
										<datalist id="strategyTypeList">
											{this.state.csdStrategyTypeList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>

									<div className="input-group">
										<label className="form-label">Incentive Type</label>
										<div className="select-group">
											<select
												value={this.state.filter_incentive_type_operator}
												onChange={(e) =>
													this.setState({ filter_incentive_type_operator: e.target.value })
												}
												className="form-input"
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											className="form-input"
											list="incentiveTypeList"
											value={this.state.filter_incentive_type}
											onChange={(e) => this.setState({ filter_incentive_type: e.target.value })}
											type="search"
										/>
										<datalist id="incentiveTypeList">
											{this.state.csdIncentiveTypeList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>

									<div className="input-group">
										<label className="form-label">Discount Description</label>
										<div className="select-group">
											<select
												value={this.state.filter_discount_description_operator}
												onChange={(e) =>
													this.setState({
														filter_discount_description_operator: e.target.value
													})
												}
												className="form-input"
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											className="form-input"
											value={this.state.filter_discount_description}
											onChange={(e) =>
												this.setState({ filter_discount_description: e.target.value })
											}
											type="search"
											list="csdDiscountDescriptionList"
										/>
										<datalist id="csdDiscountDescriptionList">
											{this.state.csdDiscountDescriptionList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>

									<div className="input-group">
										<label className="form-label">Desktop Launch</label>
										<div className="select-group">
											<select
												className="form-input"
												value={this.state.filter_desktop_launch_method_operator}
												onChange={(e) =>
													this.setState({
														filter_desktop_launch_method_operator: e.target.value
													})
												}
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											className="form-input"
											list="desktopLaunchList"
											value={this.state.filter_desktop_launch_method}
											onChange={(e) => this.setState({ filter_desktop_launch_method: e.target.value })}
											type="search"
										/>
										<datalist id="desktopLaunchList">
											{this.state.csdDesktopLaunchList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>

									<div className="input-group">
										<label className="form-label">Mobile Launch</label>
										<div className="select-group">
											<select
												className="form-input"
												value={this.state.filter_mobile_launch_method_operator}
												onChange={(e) =>
													this.setState({
														filter_mobile_launch_method_operator: e.target.value
													})
												}
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											className="form-input"
											list="mobileLaunchList"
											value={this.state.filter_mobile_launch_method}
											onChange={(e) =>
												this.setState({ filter_mobile_launch_method: e.target.value })
											}
											type="search"
										/>
										<datalist id="mobileLaunchList">
											{this.state.csdMobileLaunchList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>

									<div className="input-group">
										<label className="form-label">Pages</label>
										<div className="select-group">
											<select
												className="form-input"
												value={this.state.filter_pages_operator}
												onChange={(e) =>
													this.setState({ filter_pages_operator: e.target.value })
												}
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											className="form-input"
											list="pagesList"
											value={this.state.filter_pages}
											onChange={(e) => this.setState({ filter_pages: e.target.value })}
											type="search"
										/>
										<datalist id="pagesList">
											{this.state.csdPagesList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>

									<div className="input-group">
										<label className="form-label">Stages</label>
										<div className="select-group">
											<select
												value={this.state.filter_stages_operator}
												onChange={(e) =>
													this.setState({ filter_stages_operator: e.target.value })
												}
												className="form-input"
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											className="form-input"
											list="stagesList"
											value={this.state.filter_stages}
											onChange={(e) => this.setState({ filter_stages: e.target.value })}
											type="search"
										/>
										<datalist id="stagesList">
											{this.state.csdStagesList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>

									<div className="input-group">
										<label className="form-label">Visitors</label>
										<div className="select-group">
											<select
												className="form-input"
												value={this.state.filter_visitors_operator}
												onChange={(e) =>
													this.setState({ filter_visitors_operator: e.target.value })
												}
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											className="form-input"
											list="visitorsList"
											value={this.state.filter_visitors}
											onChange={(e) => this.setState({ filter_visitors: e.target.value })}
											type="search"
										/>
										<datalist id="visitorsList">
											{this.state.csdVisitorsList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>

									<div className="input-group">
										<label className="form-label">Geo-Locations</label>
										<div className="select-group">
											<select
												value={this.state.filter_locations_operator}
												onChange={(e) =>
													this.setState({ filter_locations_operator: e.target.value })
												}
												className="form-input"
											>
												<option value="contains">Contains</option>
												<option value="does_not_contain">Doesn't contain</option>
												<option value="equals">Equals</option>
												<option value="not_equal">Not Equal</option>
												<option value="word">Word Match</option>
											</select>
										</div>
										<input
											className="form-input"
											list="geoLocationsList"
											value={this.state.filter_locations}
											onChange={(e) => this.setState({ filter_locations: e.target.value })}
											type="search"
										/>
										<datalist id="geoLocationsList">
											{this.state.csdGeoLocationsList.map((opt, idx) => (
												<option key={idx} value={opt}>
													{opt}
												</option>
											))}
										</datalist>
									</div>
									<div className="checkbox-group">
										<label className="form-label">
											<input
												type="checkbox"
												className="form-input"
												value={"true"}
												onChange={(e) => {
													this.setState({ filter_contains_mockups: e.target.checked });
												}}
											/>
											Contains mockups
										</label>
									</div>
									<div className="checkbox-group">
										<label className="form-label">
											<input
												type="checkbox"
												className="form-input"
												value={"true"}
												onChange={(e) => {
													this.setState({
														filter_contains_deliverables: e.target.checked
													});
												}}
											/>
											Contains deliverables
										</label>
									</div>
									<div className="checkbox-group">
										<label className="form-label">
											<input
												type="checkbox"
												className="form-input"
												value={"true"}
												onChange={(e) => {
													this.setState({
														filter_contains_testing_instructions: e.target.checked
													});
												}}
											/>
											Contains testing instructions
										</label>
									</div>
								</div>
							</form>
						</div>

						<div className="search-results">
							<div className="search-header">
								<p style={{ textAlign: "right" }}>
									Found:{" "}
									{
										this.state.salesforce_records_displayed
											.filter(
												(record) =>
													record.csdCompany &&
													record.csdCompany._forms &&
													record.csdCompany._forms.length > 0
											)
											.filter(
												(record) =>
													record.csdCompany._forms.filter(this.filterForms).length > 0
											).length
									}
								</p>
								<label className="form-label">Sort&nbsp;</label>
								<select
									value={this.state.sort}
									onChange={(e) => {
										this.setState({
											sort: e.target.value
										});
									}}
								>
									<option value="">Default</option>
									<option value="az">A-Z</option>
									<option value="za">Z-A</option>
									<option value="industry">Industry</option>
									<option value="Sub_Industry__c">Sub Industry</option>
									<option value="type">Type</option>
									{/* <option value="grade">Grade</option> */}
									{/* <option value="createdDate">Created date</option>
								<option value="modifiedDate">Modified date</option> */}
								</select>
								<div style={{ lineHeight: 1, marginLeft: "1em" }}>
									<label className="form-label">
										<input
											type="checkbox"
											className=""
											checked={this.state.showData}
											onChange={(e) => {
												this.setState({
													showData: e.target.checked
												});
											}}
										/>{" "}
										Show CSD Data
									</label>
								</div>
							</div>
							<hr />

							{this.state.salesforce_records.length === 0 && (
								<div
									className="lds-ellipsis"
									style={{ position: "relative", marginBottom: "10em" }}
								>
									<div />
									<div />
									<div />
									<div />
								</div>
							)}
							{this.state.salesforce_records.length > 0 &&
								this.state.salesforce_records_displayed
									.filter(
										(record) =>
											record.csdCompany &&
											record.csdCompany._forms &&
											record.csdCompany._forms.length > 0
									)
									.filter(
										(record) => record.csdCompany._forms.filter(this.filterForms).length > 0
									)
									.sort((a, b) => {
										if (this.state.sort === "az") {
											return a.Name > b.Name ? 1 : -1;
										} else if (this.state.sort === "za") {
											return a.Name < b.Name ? 1 : -1;
										} else if (this.state.sort === "industry") {
											return a.Industry > b.Industry ? 1 : -1;
										} else if (this.state.sort === "Sub_Industry__c") {
											if (!a.Sub_Industry__c) return 1;
											return a.Sub_Industry__c > b.Sub_Industry__c ? 1 : -1;
										} else if (this.state.sort === "type") {
											return a.Type > b.Type ? 1 : -1;
										}
										// else if (this.state.sort === "grade") {
										// 	if (!a.Account_Grade__c) return 1;
										// 	if (a.Account_Grade__c === "Key Account") return -1;
										// 	return a.Account_Grade__c > b.Account_Grade__c ? 1 : -1
										// }
										return 1;
									})
									.map((record, i) => (
										<div key={i} className="salesforce-record">
											<div
												className="card"
												style={{ width: "calc(50% - 2rem)", margin: "0 1rem" }}
											>
												<h3>
													<a
														href={"/company?id=" + record.csdCompany._id}
														target="_blank"
														rel="noreferrer"
													>
														{record.Name}
													</a>
												</h3>
												<table style={{ fontSize: "0.8em" }}>
													<tbody>
														{record.Industry && (
															<tr>
																<td>
																	<b>Industry&nbsp;</b>
																</td>
																<td> {record.Industry}</td>
															</tr>
														)}
														{record.Sub_Industry__c && (
															<tr>
																<td>
																	<b>Sub Industry&nbsp;</b>
																</td>
																<td> {record.Sub_Industry__c}</td>
															</tr>
														)}
														{record.Type && (
															<tr>
																<td>
																	<b>Type&nbsp;</b>
																</td>
																<td> {record.Type}</td>
															</tr>
														)}
														{record.Account_Grade__c && (
															<tr>
																<td>
																	<b>Grade&nbsp;</b>
																</td>
																<td> {record.Account_Grade__c}</td>
															</tr>
														)}
														{record.Headquarted_In__c && (
															<tr>
																<td>
																	<b>Headquarted&nbsp;</b>
																</td>
																<td> {record.Headquarted_In__c}</td>
															</tr>
														)}
														{record.Network__r && (
															<tr>
																<td>
																	<b>Network&nbsp;</b>
																</td>
																<td>
																	{record.Network__r && record.Network__r.Name}
																</td>
															</tr>
														)}
														{record.Agency__r && (
															<tr>
																<td>
																	<b>Agency&nbsp;</b>
																</td>
																<td>{record.Agency__r && record.Agency__r.Name}</td>
															</tr>
														)}
													</tbody>
												</table>
											</div>
											{record.csdCompany &&
												record.csdCompany._forms &&
												record.csdCompany._forms.length > 0 && (
													<div style={{ width: "calc(50% - 2rem)", margin: "0 1rem" }}>
														<ul>
															{record.csdCompany._forms
																.filter(
																	(csd) =>
																		!this.state.filter_solution ||
																		csd.type === this.state.filter_solution
																)
																.filter(this.filterForms)
																.map((csd, i) => (
																	<li
																		key={i}
																		style={{
																			paddingBottom: "1rem"
																		}}
																		className="card"
																	>
																		<a
																			href={"/form?reader=1&id=" + csd._id}
																			target="_blank"
																			rel="noreferrer"
																		>
																			{csd.title}
																		</a>
																		<span
																			style={{
																				fontSize: "0.8em",
																				paddingLeft: "1rem"
																			}}
																		>
																			{" "}
																			Created{" "}
																			{new Date(csd.createdAt).toLocaleString(
																				"en-US",
																				{
																					year: "numeric",
																					month: "numeric",
																					day: "numeric"
																				}
																			)}{" "}
																			| Updated{" "}
																			{new Date(csd.updatedAt).toLocaleString(
																				"en-US",
																				{
																					year: "numeric",
																					month: "numeric",
																					day: "numeric"
																				}
																			)}
																		</span>
																		{this.state.showData && (
																			<table
																				style={{
																					fontSize: "0.7em"
																				}}
																			>
																				<tbody>
																					{csd.fields.language && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Language&nbsp;
																							</td>
																							<td>
																								{
																									csd.fields
																										.language
																								}
																							</td>
																						</tr>
																					)}
																					{csd.fields.strategy_type && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Strategy&nbsp;
																							</td>
																							<td>
																								{
																									csd.fields
																										.strategy_type
																								}
																							</td>
																						</tr>
																					)}
																					{csd.fields.incentive_type && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Incentive&nbsp;
																							</td>
																							<td>
																								{
																									csd.fields
																										.incentive_type
																								}
																							</td>
																						</tr>
																					)}
																					{csd.fields
																						.discount_description && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Discount&nbsp;
																							</td>
																							<td>
																								{
																									csd.fields
																										.discount_description
																								}
																							</td>
																						</tr>
																					)}
																					{csd.fields
																						.desktop_launch_method && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Desktop&nbsp;
																							</td>
																							<td>
																								{
																									csd.fields
																										.desktop_launch_method
																								}
																							</td>
																						</tr>
																					)}
																					{csd.fields
																						.mobile_launch_method && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Mobile&nbsp;
																							</td>
																							<td>
																								{
																									csd.fields
																										.mobile_launch_method
																								}
																							</td>
																						</tr>
																					)}

																					{csd.fields.pages && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Pages&nbsp;
																							</td>
																							<td>
																								{csd.fields.pages}
																							</td>
																						</tr>
																					)}
																					{csd.fields.stages && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Stages&nbsp;
																							</td>
																							<td>
																								{csd.fields.stages}
																							</td>
																						</tr>
																					)}
																					{csd.fields.visitors && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Visitors&nbsp;
																							</td>
																							<td>
																								{
																									csd.fields
																										.visitors
																								}
																							</td>
																						</tr>
																					)}
																					{csd.fields.geo_locations && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Geo-locations&nbsp;
																							</td>
																							<td>
																								{
																									csd.fields
																										.geo_locations
																								}
																							</td>
																						</tr>
																					)}
																					{csd.fields.mockups && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Mockups&nbsp;
																							</td>
																							<td
																								className="reader-text-box dont-break-out"
																								dangerouslySetInnerHTML={{
																									__html: linkifyHtml(
																										csd.fields
																											.mockups,
																										{
																											defaultProtocol:
																												"https",
																											target: "_blank"
																										}
																									)
																								}}
																							/>
																						</tr>
																					)}
																					{csd.fields.deliverables && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Deliverables&nbsp;
																							</td>
																							<td
																								className="reader-text-box dont-break-out"
																								dangerouslySetInnerHTML={{
																									__html: linkifyHtml(
																										csd.fields
																											.deliverables,
																										{
																											defaultProtocol:
																												"https",
																											target: "_blank"
																										}
																									)
																								}}
																							/>
																						</tr>
																					)}
																					{csd.fields
																						.testing_instructions && (
																						<tr>
																							<td
																								style={{
																									fontWeight:
																										"bold"
																								}}
																							>
																								Testing
																								Instructions&nbsp;
																							</td>
																							<td
																								className="reader-text-box dont-break-out"
																								dangerouslySetInnerHTML={{
																									__html: linkifyHtml(
																										csd.fields
																											.testing_instructions,
																										{
																											defaultProtocol:
																												"https",
																											target: "_blank"
																										}
																									)
																								}}
																							/>
																						</tr>
																					)}
																				</tbody>
																			</table>
																		)}
																	</li>
																))}
														</ul>
													</div>
												)}
										</div>
									))}
						</div>
					</div>
				</DashboardWrapperEl>
			</React.Fragment>
		);
	}
}

export default SearchWrapper;
