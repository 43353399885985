import styled, {createGlobalStyle} from "styled-components";
export const GlobalStyle = createGlobalStyle`
	#light {
		color: var(--txt-dg);
		#body p {
			color: var(--txt-dg);
		}
		.search-field {
			background: #fff;
			color: var(--txt-dg);
		}
		.dashboard-row {
			background: #fff !important;
			.favicon {
				border: 1px solid var(--bg-l1);
				background: var(--bg-l1);
			}
			.siteid span {
				color: var(--txt-dg);
			}
		}
		.input-group {
			.form-input {
				/* background-color: rgba(0, 0, 0, 0.05); */
			}
		}
		.search-wrapper .search-header h1 {
			color: #000;
		}
	}
`;
export const DashboardWrapperEl = styled.div`
	display: block;
	position: relative;
	font-size: 1em;
	h1 {
		color: var(--txt-dg);
		font-size: 1.5em;
		padding-bottom: 1em;
	}
	& .body-wrapper {
		position: absolute;
		top: 60px;
		padding: 25px 25px 100px 25px;
		&.show {
			animation: dashboard-body-show-ani 0.25s ease-in-out 1 forwards;
			-webkit-animation: dashboard-body-show-ani 0.25s ease-in-out 1 forwards;
			-moz-animation: dashboard-body-show-ani 0.25s ease-in-out 1 forwards;
			-o-animation: dashboard-body-show-ani 0.25s ease-in-out 1 forwards;
		}
	}
	.search-wrapper {
		display: flex;
		flex-wrap: wrap;
		.search-header {
			h1 {
				color: #fff;
				font-family: "freight-display-pro", "Georgia", serif;
			}
			width: 100%;
			padding: 0 2.5%;
			select {
				background-color: #f5f4f4;
				color: #555;
				border-color: hsl(0, 0%, 80%) !important;
				border-radius: 4px !important;
				border-style: solid !important;
				border-width: 1px !important;
			}
		}
			.form-input {
				background-color: #f5f4f4;
				color: #555;
				border-color: hsl(0, 0%, 80%) !important;
				border-radius: 4px !important;
				border-style: solid !important;
				border-width: 1px !important;
				& > div {
					border-color: transparent;
					/* background: none !important; */
				}
			}
			.select-group {
				margin-right: 1em;
			}
		.search-results {
			width: 100%;
			.salesforce-record {
				border-bottom: 0.5px solid;
				padding-bottom: 1em;
				margin-bottom: 1em;
				display: flex;
				& > div {
					flex: 1 1;
				}
			}
			.search-header {
				display: flex;
				label {
					margin-left: auto;
				}
			}
		}
		.card {
			width:  100%;
			margin-bottom: 1rem;
			padding: 1em;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
			border-radius: 3px;
			.card-header {
				padding-bottom: 1em;
			}
		}
		.checkbox-group {
			margin: 0.5em 0 1em 0;
			line-height: 1;
			input {
				margin-right: 0.5em;
			}
		}
		.form-label {
			min-width: 10em;
			text-align: right;
			padding-right: 1em;
			font-size: 0.8em;
		}
		.input-group {
			display: flex;
			padding-bottom: 0.5em;
			.form-input {
				width: 100%;
				border: none;
				/* 
				background-color: rgba(255, 255, 255, 0.05); */
				.react-date-picker__wrapper {
					border: none;
				}
			}
			select {
				min-width: 8em;
				height:2em;
			}
		}
	}
	@keyframes dashboard-body-show-ani {
		0% {
			opacity: 0;
			top: 70px;
		}
		100% {
			opacity: 1;
			top: 60px;
		}
	}
	.badge {
		color: #fff !important;
		text-transform: uppercase;
		font-weight: 900;
		&.tt {
			background: var(--darkgreen) !important;
		}
		&.lc {
			background: var(--violet) !important;
		}
		&.pc,
		&.em {
			background: var(--blue) !important;
		}
		&.nc {
			background: var(--gray) !important;
		}
	}
	& .dashboard-column {
		display: inline-block;
		vertical-align: top;
		min-height: 100px;
		position: relative;
		width: 25%;
		padding: 0 5px 15px 5px;
		max-width: 400px;
	}
	.search-all-wrapper .favicon-wrapper {
		margin: 0 10px 15px !important;
		float: left;
	}
	& .body-wrapper.two-col .dashboard-column {
		width: 48%;
		max-width: 500px;
	}
	& .dashboard-column.search-wrapper input::placeholder {
		color: var(--txt-lg);
	}
	& .dashboard-column.search-all-wrapper {
		.dashboard-row {
			min-height: 0;
			padding: 0.5em;
			margin:0;
		}
		a {
			color: var(--blue);
		}
		input::placeholder {
			color: var(--txt-lg);
		}
	}
	& .dashboard-column h3 {
		color: var(--txt-lg);
		font-size: 13px;
		margin-bottom: 23px;
		vertical-align: top;
		span {
			font-size: 13px;
		}
		img {
			height: 18px;
			width: 18px;
			margin: -1px 8px 0 0;
			vertical-align: top;
		}
		img.search {
			background-image: var(--icon-search-lg);
		}
		img.history {
			background-image: var(--icon-history-lg);
		}
		img.activity {
			background-image: var(--icon-activity-lg);
		}
		img.legacy {
			background-image: var(--icon-legacy-lg);
		}
	}
	& .dashboard-column .section-prompt {
		color: var(--txt-lg);
		text-align: center;
		font-size: 12px;
		margin-top: 10px;
		padding: 0 40px;
	}
	& .dashboard-row {
		display: block;
		background: var(--bg-d2);
		border-radius: 3px;
		border: 1px solid transparent;
		transition: background 0.05s, border 0.05s, top 0.5s, -moz-transform ease-in-out 0.05s;
		position: relative;
		min-height: 60px;
		&:hover {
			cursor: pointer;
			border: 1px solid var(--blue);
		}
		.click-zone {
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: 1;
			a {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				color: transparent;
			}
		}
		.favicon-wrapper {
			margin: 10px 0 0 15px;
			display: inline-block;
			vertical-align: top;
			text-align: center;
		}
		.favicon {
			font-size: 12px;
			padding: 3px;
			border-radius: 3px;
			width: 22px;
			height: 22px;
			border: 1px solid var(--bg-d0);
			background: var(--bg-d0);
		}
		.form-type {
			text-transform: uppercase;
			font-size: 10px;
			padding: 5px;
			border-radius: 3px;
			font-weight: 900;
			width: 30px;
			margin-top: 3px;
			&.tt {
				background: var(--darkgreen);
			}
			&.lc {
				background: var(--violet);
			}
			&.pc {
				background: var(--blue);
			}
			&.em {
				background: var(--blue);
			}
			&.nc {
				background: var(--gray);
			}
		}
		.status-wrapper {
			padding: 0;
			margin: 1px 0 0 0;
			height: 12px;
		}
		.status {
			width: 4px;
			height: 4px;
			border-radius: 2px;
			background: white;
			margin: 3px auto 0 auto;
			display: inline-block;
			vertical-align: top;
		}
		.status.active {
			background: var(--green);
		}
		.status.paused {
			background: var(--red);
		}
		.form-count {
			display: inline-block;
			vertical-align: top;
			color: var(--txt-lg);
			font-size: 10px;
			margin-left: 3px;
			margin-top: -2px;
			padding: 0;
		}
		.menu-hover-wrapper {
			height: 20px;
			width: 20px;
			position: absolute;
			top: 10px;
			right: 4px;
			border-radius: 3px;
			z-index: 2;
			text-align: right;
			.menu-icon {
				position: absolute;
				top: 0;
				right: 0;
				height: 20px;
				width: 20px;
				z-index: 10;
				color: white;
				font-size: 20px;
				text-align: center;
			}
			&:hover {
				cursor: pointer;
				background: var(--bg-l1);
				padding: 4px 0;
				width: auto;
				height: auto !important;
				z-index: 100 !important;
				& > .menu-icon {
					display: none;
				}
			}
			.menu-wrapper {
				display: none;
				height: auto;
			}
			.menu-btn {
				font-size: 12px;
				font-weight: 700;
				color: var(--txt-d);
				text-align: left;
				margin: 0 4px;
				border-radius: 2px;
				padding: 2px 60px 0 10px;
			}
			.menu-btn:hover {
				color: white;
			}
			.menu-btn.edit:hover {
				background: var(--blue);
			}
			.menu-btn.clone:hover {
				background: var(--lt-green-bg);
			}
			.menu-btn.delete:hover {
				background: var(--red);
			}
		}
		.menu-hover-wrapper:hover > .menu-wrapper {
			display: block;
		}
		.delete-btn:hover > span {
			opacity: 1;
		}
		.text {
			padding: 10px 22px 10px 5px;
			height: auto !important;
			display: inline-block;
			vertical-align: top;
			width: calc(100% - 60px);
			text-align: left;
			margin-left: 5px;
		}
		.title {
			font-size: 13px;
		}
		.title span {
			color: var(--txt-lg);
			border: 1px solid;
			font-size: 10px;
			padding: 2px 4px;
			position: relative;
			top: -1px;
			border-radius: 3px;
			margin-left: 5px;
		}
		.title.siteid span {
			display: inline-block;
			margin-top: 3px;
			margin-left: 0;
		}
		.siteid.search span {
			background: rgba(244, 128, 36, 0.35);
			padding: 0 3px;
		}
		.title .salesforce-icon {
			display: inline-block;
			vertical-align: top;
			height: 4px;
			width: 4px;
			border-radius: 2px;
			margin-left: 6px;
			margin-top: 8px;
			background: var(--salesforce-blue);
			padding: 0;
			border: none;
		}
		.title.legacy {
			font-size: 13px;
			color: var(--teal);
			padding: 3px 0 1px 0;
		}
		.user {
			color: var(--txt-lg);
			font-size: 11px;
			margin-top: 1px;
			font-weight: 500;
		}
		.user span.green {
			color: var(--green);
		}
		.user span.yellow {
			color: var(--darkyellow);
		}
		.user span.orange {
			color: var(--orange);
		}
		.siteid {
			margin-top: 1px;
			font-size: 11px;
			color: var(--txt-lg);
		}
		.siteid span {
			color: white;
		}
	}
	& .search-field {
		background: var(--bg-d0-dash);
		border: 1px solid transparent;
		padding: 1em;
		outline: none;
		width: 100%;
		font-size: 13px;
		border-radius: 3px;
		text-align: center;
		color: white;
		&:focus {
			border: 1px solid var(--blue);
		}
	}
	& .dashboard-column {
		.legacy-wrapper {
			margin: 25px 0 0 0;
			.title {
				color: var(--teal);
			}
		}
	}
	@media screen and (max-width: 800px) {
		& .body-wrapper {
			padding: 25px 40px 100px 40px;
		}
		& .dashboard-column {
			width: 100%;
			max-width: unset;
			display: block;
			margin: 25px auto 0 auto;
		}
		& .body-wrapper.two-col .dashboard-column {
			width: 100%;
			max-width: unset;
		}
	}
`;
