// Resources
import decode from "jwt-decode";
import axios from "axios";
import Cookies from "universal-cookie";

// Globals
let cookies;

export default class AuthHelperMethods {
	// Initializing important variables
	constructor(domain) {
		// THIS LINE IS ONLY USED IN PRODUCTION MODE!
		this.domain = domain || "http://localhost:3001"; // API server domain
	}

	getIP = () => {
		// Get a token from api server using fetch api
		return this.fetch("https://freeipapi.com/api/json", {
			method: "GET"
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	login = (token, ip) => {
		const post_data = {
			token: token,
			ip: ip
		};
		return this.fetch("/login", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	loginInit = () => {
		return this.fetch("/login_init", {
			method: "POST"
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getProfile = () => {
		return this.fetch("/api/v1/user/profile", {
			method: "POST"
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getUsers = () => {
		return this.fetch("/api/v1/user/fetch_all", {
			method: "POST"
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	updateUserDepartment = (post_data) => {
		return this.fetch("/api/v1/user/update_department", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	updateUserEid = (post_data) => {
		return this.fetch("/api/v1/user/update_eid", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	updateUserSettings = (post_data) => {
		return this.fetch("/api/v1/user/update_settings", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getActivitiesQuick = (id) => {
		return this.fetch("/api/v1/activity/quick_fetch", {
			method: "POST",
			body: JSON.stringify({ id: id })
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getActivitiesTypeID = (post_data) => {
		return this.fetch("/api/v1/activity/fetch_by_type_id", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getCompaniesInit = () => {
		return this.fetch("/api/v1/company/init_fetch", {
			method: "POST"
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getMoreCompanies = (post_data) => {
		return this.fetch("/api/v1/company/fetch_more", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getLegacyInit = () => {
		return this.fetch("/api/v1/legacy/init_fetch", {
			method: "POST"
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getFormsInit = (post_data) => {
		return this.fetch("/api/v1/form/init_fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getMoreForms = (post_data) => {
		return this.fetch("/api/v1/form/fetch_more", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getMoreFormsLegacy = (post_data) => {
		return this.fetch("/api/v1/form/fetch_more_legacy", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getCompaniesAll = async () => {
		return this.fetch("/api/v1/company/fetch_all", {
			method: "POST"
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getCompaniesSearch = (post_data) => {
		return this.fetch("/api/v1/company/search_fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getLegacySearch = (post_data) => {
		return this.fetch("/api/v1/legacy/search_fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getFormsSearch = (post_data) => {
		return this.fetch("/api/v1/form/search_fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getCompany = (post_data) => {
		return this.fetch("/api/v1/company/fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getForm = (post_data) => {
		return this.fetch("/api/v1/form/fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getLegacy = (post_data) => {
		return this.fetch("/api/v1/legacy/fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	editLegacy = (post_data) => {
		return this.fetch("/api/v1/legacy/edit", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	searchPage = (post_data) => {
		return this.fetch("/api/v1/form/search_page", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	searchAll = (post_data) => {
		return this.fetch("/api/v1/form/search_all", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getFields = (post_data) => {
		return this.fetch("/api/v1/form/fetch_fields", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getChatResponse = (post_data) => {
		return this.fetch("/api/v1/form/get_chat_response", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	fixFields = (post_data) => {
		return this.fetch("/api/v1/form/fix_fields", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getFieldRevisions = (post_data) => {
		return this.fetch("/api/v1/form/fetch_field_revisions", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	updateField = (post_data) => {
		return this.fetch("/api/v1/form/update_field", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	createGroup = (post_data) => {
		return this.fetch("/api/v1/form/create_group", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	deleteGroup = (post_data) => {
		return this.fetch("/api/v1/form/delete_group", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	deleteCompany = (post_data) => {
		return this.fetch("/api/v1/company/delete", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	deleteForm = (post_data) => {
		return this.fetch("/api/v1/form/delete", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getLinkPreview = (host) => {
		return this.fetch("/api/v1/company/get_link_preview", {
			method: "POST",
			body: JSON.stringify({ url: host })
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	createCompany = (post_data) => {
		const url = post_data._id ? "/api/v1/company/update" : "/api/v1/company/create";
		return this.fetch(url, {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	createCSD = (post_data) => {
		return this.fetch("/api/v1/form/create", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	updateCSD = (post_data) => {
		return this.fetch("/api/v1/form/update", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	cloneCSD = (post_data) => {
		return this.fetch("/api/v1/form/clone", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	deleteFormv = (post_data) => {
		return this.fetch("/api/v1/form/delete_formv", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	createFormv = (post_data) => {
		return this.fetch("/api/v1/form/create_formv", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	updateFormvName = (post_data) => {
		return this.fetch("/api/v1/form/update_formv_name", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	forceShowHideField = (post_data) => {
		return this.fetch("/api/v1/form/force_show_hide", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	updatePrimarySiteId = (post_data) => {
		return this.fetch("/api/v1/form/update_primary_siteid", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	// -----------
	// Task Routes
	// -----------
	createTask = (post_data) => {
		return this.fetch("/api/v1/task/create", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	updateTask = (post_data) => {
		return this.fetch("/api/v1/task/update", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	// ----------------
	// CSD Routes (New)
	// ----------------
	initCSD = (post_data) => {
		return this.fetch("/api/v1/form/init", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	getCSD = (post_data) => {
		return this.fetch("/api/v1/form/get", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	// ---------------
	// S3 Routes (New)
	// ---------------
	getS3Url = () => {
		return this.fetch("/api/v1/s3/url", {
			method: "POST"
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	updateUserProfileImage = (post_data) => {
		return this.fetch("/api/v1/user/update_profile_image", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	uploadSingleImage = async (post_data) => {
		const result = await axios.post("/api/v1/s3/upload_image", post_data, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + this.getToken()
			}
		});
		return result.data;
	};

	deleteSingleImage = (post_data) => {
		return this.fetch("/api/v1/s3/delete_image", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	// ---------
	// QA Routes
	// ---------
	fetchQA = (post_data) => {
		return this.fetch("/api/v1/qa/fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	createQA = (post_data) => {
		return this.fetch("/api/v1/qa/create", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	updateQA = (post_data) => {
		return this.fetch("/api/v1/qa/update", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	createBug = (post_data) => {
		return this.fetch("/api/v1/qa/create_bug", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	// ---------
	// Wiki Routes
	// ---------
	fetchWikiArticle = (post_data) => {
		return this.fetch("/api/v1/wiki/fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	fetchWikiAll = (post_data) => {
		return this.fetch("/api/v1/wiki/fetch_all", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	addArticle = (post_data) => {
		return this.fetch("/api/v1/wiki/add_article", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	editArticle = (post_data) => {
		return this.fetch("/api/v1/wiki/edit_article", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	// ---------
	// Wiki Routes
	// ---------
	fetchAdmin = (post_data) => {
		return this.fetch("/api/v1/admin/fetch", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	addAdmin = (post_data) => {
		return this.fetch("/api/v1/admin/add", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	editAdmin = (post_data) => {
		return this.fetch("/api/v1/admin/edit", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	// ----------
	// Dev Routes
	// ----------
	initTags = (post_data) => {
		return this.fetch("/api/v1/tag/init", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	createTag = (post_data) => {
		return this.fetch("/api/v1/tag/create", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	createExample = (post_data) => {
		return this.fetch("/api/v1/example/create", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	exampleClicked = (post_data) => {
		return this.fetch("/api/v1/example/clicked", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	deleteExample = (post_data) => {
		return this.fetch("/api/v1/example/delete", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	updateClaps = (post_data) => {
		return this.fetch("/api/v1/example/update_claps", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
	convertMjml = (post_data) => {
		return this.fetch("/api/v1/mjml/convert", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	// -----
	// UTILS
	// -----
	loggedIn = () => {
		// Save entry url in cookie, so we can redirect the user back the that page after they log in
		// noinspection JSValidateTypes
		cookies = new Cookies();
		if (!cookies.get("entryURL")) {
			cookies.set("entryURL", encodeURIComponent(window.location.href), { path: "/", maxAge: 60 * 5 }); // 5 min
		}

		// Checks if there is a saved token, and it's still valid
		const token = this.getToken(); // Getting token from localstorage
		if (!token || typeof token === "undefined") {
			// No token found
			return false;
		} else {
			// Token exists, check if it's expired
			return !this.isTokenExpired(token);
		}
	};

	isTokenExpired = (token) => {
		try {
			if (typeof token !== "undefined" && token.length > 10) {
				const decoded = decode(token);
				return decoded.exp < Date.now() / 1000;
			} else {
				// Token is undefined, user is not logged in (token is expired)
				return true;
			}
		} catch (err) {
			console.log("[ isTokenExpired ] Expired check failed!", token);
			this.logout();
			return true;
		}
	};

	setToken = (token, stayLoggedIn) => {
		if (stayLoggedIn) {
			// Saves user token to localStorage
			localStorage.setItem("csd_login_token", token);
		} else {
			// Save token to window
			window.csd_login_token = token;
		}
	};

	getToken = () => {
		// Retrieves the user token if exists
		return localStorage.getItem("csd_login_token") || window.csd_login_token;
	};

	logout = () => {
		// Clear user token and key
		localStorage.removeItem("csd_login_token");
		window.csd_login_token = null;
		// Revoke token
		const google_oauth_token = localStorage.getItem("google_oauth_token");
		if (google_oauth_token) {
			const url = "https://accounts.google.com/o/oauth2/revoke?token=" + google_oauth_token;
			axios.get(url).then((response) => {
				console.log("[ logout ] Google OAuth token revoked:", response);
				localStorage.removeItem("google_oauth_token");
			});
		}
	};

	getConfirm = () => {
		// Using jwt-decode npm package to decode the token
		return decode(this.getToken());
	};

	fetch = (url, options) => {
		// Performs api calls sending the required authentication headers
		const headers = {
			Accept: "application/json"
		};
		headers["Content-Type"] = "application/json";
		// Setting Authorization header
		if (this.loggedIn()) {
			headers["Authorization"] = "Bearer " + this.getToken();
		}

		return fetch(url, {
			headers,
			...options
		})
			.then(this._checkStatus)
			.then((response) => response.json());
	};

	_checkStatus = (response) => {
		// raises an error in case response status is not a success
		if (response.status >= 200 && response.status < 300) {
			// Success status lies between 200 to 300
			return response;
		} else {
			const error = new Error(response.statusText);
			error["response"] = response;
			throw error;
		}
	};

	sudoAction = (post_data) => {
		return this.fetch("/api/v1/user/sudo_action", {
			method: "POST",
			body: JSON.stringify(post_data)
		}).then((res) => {
			return Promise.resolve(res);
		});
	};

	// ----------------------------
	// -------- Dashboard ---------
	// ----------------------------
	querySalesforce = (q) => {
		return this.fetch("/api/v1/data/salesforce_query?q=" + encodeURIComponent(q), {
			method: "POST"
		}).then((res) => {
			return Promise.resolve(res);
		});
	};
}
