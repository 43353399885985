import React from "react";
import Moment from "react-moment";
import "moment-timezone";

export function CompanySearch(props) {
	Moment.globalTimezone = Intl.DateTimeFormat()["resolvedOptions"]()["timeZone"];
	let domain = document.location.protocol + "//" + document.location.host;
	let generateTimeStyles = (date) => {
		if (!date) return "";
		const ONE_DAY = 60 * 60 * 1000 * 24; /* ms */
		const ONE_WEEK = 60 * 60 * 1000 * 24 * 7; /* ms */
		const ONE_MONTH = 60 * 60 * 1000 * 24 * 30; /* ms */
		const now = new Date();
		if (now - date < ONE_DAY) {
			return "green";
		} else if (now - date < ONE_WEEK) {
			return "yellow";
		} else if (now - date < ONE_MONTH) {
			return "orange";
		}
		return "";
	};
	let arr = props.arr
		.map((a) => {
			let h = props.formsHistory.find((fh) => fh._form._id === a._id);
			if (h) {
				return {
					lastUpdated: h.lastUpdated,
					lastUpdatedBy: h.lastUpdatedBy,
					...a
				};
			}
			return a;
		})
		.sort((a, b) => {
			if (props.sort === "createdAt") {
				return a.createdAt > b.createdAt ? -1 : 1;
			}
			if (props.sort === "lastUpdated") {
				if (!b.lastUpdated) return -1;
				return a.lastUpdated > b.lastUpdated ? -1 : 1;
			}
			if (props.sort === "alpha") {
				return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
			}
			return 1;
		});
	return (
		<div className="company-column search-wrapper">
			<form className="filter-wrapper" onSubmit={(e) => console.log(e)}>
				{/* <label >Filter</label> */}
				<input
					id="company-search-field"
					className="search-field"
					placeholder={"Search CSDs & SiteIDs"}
					name="search"
					type="text"
					onChange={props._changeSearch}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
						}
						console.log(e);
					}}
					autoFocus={true}
					autoComplete={"off"}
					list="company-filter"
				/>
				
				<datalist id="company-filter">
					{[
						"tt",
						"lc",
						"em",
						"pc",
						"ce",
						"nc"
				].map(tag => <option key={tag} value={tag} /> )}
				</datalist>
			</form>
			<div className="sort-wrapper">
				<label>Sort by </label>
				<select id="company-sort" onChange={props.handleSort} value={props.sort}>
					<option value="createdAt">Created Date</option>
					<option value="lastUpdated">Last Viewed</option>
					<option value="alpha">A-Z</option>
				</select>
			</div>
			{props.displaySearchLoader && (
				<div className="lds-ellipsis search">
					<div />
					<div />
					<div />
					<div />
				</div>
			)}
			{arr.map((item) => {
				if (typeof item.salesforceCompanyId !== "undefined") {
					// Render Legacy Forms
					let type = props.getType(item.type);
					return (
						<div className="company-row" key={item._id}>
							<div className="click-zone">
								<a className="noselect" href={domain + "/legacy?id=" + item.formId}>
									{item.title}
								</a>
							</div>
							<div className={"favicon-wrapper"}>
								<p className={"form-type " + type}>{type}</p>
							</div>
							<div className="text">
								<p className="title legacy">{item.title}</p>
								<p className="user">
									Created by {item.username} •{" "}
									<span className={generateTimeStyles(new Date(item.createdDateUTC))}>
										<Moment fromNow>{item.createdDateUTC}</Moment>
									</span>
								</p>
								{item.lastUpdatedBy && (
									<p className="user">
										Last updated by {item.lastUpdatedBy} •{" "}
										<span className={generateTimeStyles(new Date(item.updatedAt))}>
											<Moment fromNow>{item.updatedAt}</Moment>
										</span>
									</p>
								)}
								{item.lastUpdated && (
									<p className="user">
										Last viewed •{" "}
										<span className={generateTimeStyles(new Date(item.lastUpdated))}>
											<Moment fromNow>{item.lastUpdated}</Moment>
										</span>
									</p>
								)}
								{item.siteID && (
									<p className="siteid">
										Site ID • <span>{item.siteID}</span>
									</p>
								)}
							</div>
						</div>
					);
				} else if (typeof item._form !== "undefined" && item._form._id && props.searchSiteID && item._formv && item._formv.version_main) {
					// Render fields (via siteid search)
					return (
						<div className="company-row" key={item._form._id + "_" + item._formv.version_main}>
							<div className="click-zone">
								<a className="noselect" href={domain + "/form?id=" + item._form._id + "&v=" + item._formv.version_main}>
									{item._form.title}
								</a>
							</div>
							<div className={"favicon-wrapper"}>
								<p className={"form-type " + item._form.type}>{item._form.type}</p>
							</div>
							<div className="text">
								<p className="title">{item._form.title} 
									<span className="badge">v{item._formv.version_main}</span>
									 <span className="badge">{props.searchSiteID}</span>
								</p>
								<p className="user">
									Created by {item._form.createdBy} •{" "}
									<span className={generateTimeStyles(new Date(item._form.createdAt))}>
										<Moment fromNow>{item._form.createdAt}</Moment>
									</span>
									{item.lastUpdated && (
										<>
											{" | "}Last viewed •{" "}
											<span className={generateTimeStyles(new Date(item.lastUpdated))}>
												<Moment fromNow>{item.lastUpdated}</Moment>
											</span>
										</>
									)}
								</p>
							</div>
						</div>
					);
				} else if (item._id) {
					// Render normal form search results
					return (
						<div className="company-row" key={item._id}>
							<div className="menu-hover-wrapper">
								<div className="menu-icon">&#8942;</div>
								<div
									className="menu-wrapper"
									data-id={item._id}
									data-formtype={item.type}
									data-title={item.title}
								>
									<div className="menu-btn clone" onClick={props._handleCloneFormClicked}>
										Clone
									</div>
									<div className="menu-btn edit" onClick={props._handleEditFormClicked}>
										Edit
									</div>
									{(props.user.type === "sudo" ||
										props.user.type === "admin" ||
										props.user._id === item._user) && (
										<div className="menu-btn delete" onClick={props._handleDeleteFormClicked}>
											Delete
										</div>
									)}
								</div>
							</div>
							<div className="click-zone">
								<a className="noselect" href={domain + "/form?id=" + item._id}>
									{item.title}
								</a>
							</div>
							<div className={"favicon-wrapper"}>
								<p className={"form-type " + item.type}>{item.type}</p>
							</div>
							<div className="text">
								<p className="title">{item.title}</p>
								<p className="user">
									Created by {item.createdBy} •{" "}
									<span className={generateTimeStyles(new Date(item.createdAt))}>
										<Moment fromNow>{item.createdAt}</Moment>
									</span>
									{item.lastUpdated && (
										<>
											{" | "}Last viewed •{" "}
											<span className={generateTimeStyles(new Date(item.lastUpdated))}>
												<Moment fromNow>{item.lastUpdated}</Moment>
											</span>
										</>
									)}
								</p>
								{item.site_id && (
									<p className="siteid">
										Site ID • <span>{item.site_id}</span>
									</p>
								)}
							</div>
						</div>
					);
				}
				return "";
			})}
		</div>
	);
}
